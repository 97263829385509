$(document).ready(function () {

  const togglePasswordVisibility = function (event) {
    event.preventDefault();
    var elm = $('#user_password');

    if (elm[0].type == 'text') {
      $(event.currentTarget).addClass('show-password');
      elm[0].type = 'password';
    } else {
      $(event.currentTarget).removeClass('show-password');
      elm[0].type = 'text';
    }
  }

  if ($(".login-password-eye").length) {
    $(".login-password-eye").on('click', togglePasswordVisibility);
  }
})
